.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgb(255, 255, 255);
  
}

.center-div {
  text-align: center;
  background-color: white;
  padding: 60px;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 0px 10px 0px #3c3b3b88;
}

.logo {
  width: 50%;
}
.logoDesc {
  font-weight: 700;
  font-size: 14px;
  line-height: 27px;
  text-align: center;
  color: #db2874;
}

.logintxt {
  font-weight: 500;
  font-size: 36px;
  line-height: 54px;
  margin-bottom: 10%;
  color: #151f47;
}
a.underline {
  text-decoration: none;
  border-bottom: 3px solid black;
  float: right;
}
.txtField {
  margin: 15% 0;
}
.loginBtn {
  background: linear-gradient(180deg, #00c1ee 0%, #00a2d5 100%);
  border-radius: 4.56667px;
}
  .box {
    width: 500px;
    height: 300px;
   
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
