@import url(./App.darktheme.css);
.app {
    width: 100%;
    min-height: 100vh;
    background: #dddbd1;
    position: relative;
}

.app::before {
    width: 100%;
    height: 120px;
    top: 0;
    left: 0;
    background: rgb(0, 150, 136);
    position: absolute;
    content: "";
    z-index: 1;
}

.app__mobile-message {
    display: none;
}

.app-content {
    width: 100%;
    height: 100vh;
    max-width: 1450px;
    margin: 0 auto;
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, .06), 0 2px 5px 0 rgba(0, 0, 0, .2);
    position: relative;
    z-index: 100;
    display: flex;
    overflow: hidden;
}

@media screen and (max-width: 500px) {
    .app__mobile-message {
        padding-top: 200px;
        text-align: center;
        font-size: 1.2rem;
        display: block;
    }
    .app-content {
        display: none;
    }
}

@media screen and (min-width: 1450px) {
    .app {
        padding: 20px;
    }
    .app-content {
        height: calc(100vh - 40px);
    }
}